import modulesService from '@/services/modules.service';
import ModuleInterface from '@/services/interface/entities/module.interface';
import PictureEntity from '@/services/entities/picture.entity';
import ModuleFactory from '@/services/entities/module.factory';

export default {
  namespaced: true,
  state: {
    open: false,
    loading: false,
    module: null,
  },
  mutations: {
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    SET_OPEN(state: any, payload: boolean) {
      state.open = payload;
    },
    SET_RESULT(state: any, payload: ModuleInterface | null) {
      if (!payload) {
        state.module = null;
        return;
      }
      state.module = ModuleFactory.buildOne(payload);
    },
  },
  actions: {
    async setModule(context: any, moduleId: number) {
      context.commit('SET_LOADING', true);
      context.commit('SET_OPEN', true);
      const response = await modulesService.fetchOne(moduleId);
      context.commit('SET_RESULT', response.data.module);
      context.commit('shoppingCart/ADD_MODULE', moduleId, { root: true });
      context.commit('SET_LOADING', false);
    },
  },
};
