// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import { AlertInterface } from '@/store/notifications/alert.interface';

function alertInterfaceFactory(
  type: 'success'|'danger'|'warning'|'info',
  message: string,
  duration = -1,
): AlertInterface {
  return {
    message,
    timing: duration,
    type,
    dismissOnClick: true,
  };
}

export function alertSuccess(message: string, duration = -1) {
  store.commit('notifications/PUSH_ALERT', alertInterfaceFactory('success', message, duration));
}

export function alertDanger(message: string, duration = -1) {
  store.commit('notifications/PUSH_ALERT', alertInterfaceFactory('danger', message, duration));
}

export function alertWarning(message: string, duration = -1) {
  store.commit('notifications/PUSH_ALERT', alertInterfaceFactory('warning', message, duration));
}

export function alertInfo(message: string, duration = -1) {
  store.commit('notifications/PUSH_ALERT', alertInterfaceFactory('info', message, duration));
}
