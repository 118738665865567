export default {
  API_URL: 'https://admin.lodylearn.fr/api',
  IMAGE_DOMAIN: 'https://lodylearn.fr',
  // eslint-disable-next-line max-len
  STRIPE_PUBLISHABLE_KEY: 'pk_live_51Js66xEQUSyIG01C10hlbrIi0fzw6UClnhljqa1j2cKx10ucYVMb7lknIQDZwqbJWVcRlc9A9SdaxvSSCyFsFJyC00ERdCnubs',
  API_VERSION: '2020-08-27',
  LOCALE: 'fr',
  RECAPTCHA_KEY: '6LcOxDMiAAAAALY-65XcT1nXf4Y8TK4BespEJvla',
  CHECKOUT_PAGE: 'https://lodylearn.fr/paiement/run',
  USE_GTM: true,
};
