import AppService from '@/services/app.service';
import ApiResponseInterface from '@/services/interface/api-response.interface';

class TipixsService extends AppService {
  async fetchAll() {
    return this.exec<ApiResponseInterface>({
      url: '/v1/tipixs',
    });
  }

  async fetchTipix(id: any) {
    return this.exec<ApiResponseInterface>({
      url: `/v1/tipixs/${id}`,
    });
  }
}

const tipixService = new TipixsService();
export default tipixService;
