import { SearchBarInterface } from '@/store/search/search-bar.interface';

export default {
  namespaced: true,
  state: {
    terms: [],
    hasChanges: false,
  },
  mutations: {
    ADD_TERM(state: SearchBarInterface, value: string): void {
      state.hasChanges = true;
      if (state.terms.map((term: {value: string}) => term.value).indexOf(value) !== -1) return;
      state.terms.push({
        type: 'term',
        value,
      });
    },
    RESET(state: SearchBarInterface): void {
      state.hasChanges = state.terms.length > 0;
      state.terms = [];
    },
    ADD_KEYWORD(state: SearchBarInterface, value: string): void {
      state.hasChanges = true;
      if (state.terms.map((term: {value: string}) => term.value).indexOf(value) !== -1) return;
      state.terms.push({
        type: 'keyword',
        value,
      });
    },
    REMOVE_INDEX(state: SearchBarInterface, index: number): void {
      state.hasChanges = true;
      state.terms.splice(index, 1);
    },
    COMMIT(state: SearchBarInterface): void {
      state.hasChanges = false;
    },
  },
};
