import TopBarInterface from '@/store/top-bar/top-bar.interface';
import ROUTE from '@/router/collection';

export default {
  namespaced: true,
  state: {
    open: false,
    navigations: [
      {
        label: 'Modules',
        link: { name: ROUTE.Search },
      },
      {
        label: 'TipiX',
        link: { name: ROUTE.TipiX },
      },
      // {
      //   label: 'La plateforme',
      //   link: { name: ROUTE.Platform },
      // },
      // {
      //   label: 'Offres Ecole/Entreprise',
      //   link: { name: ROUTE.Offers },
      // },
      // {
      //   label: 'Live & MasterClass',
      //   link: { name: ROUTE.LiveMasterClass },
      // },
    ],
  },
  mutations: {
    SET_OPEN(state: TopBarInterface, payload: boolean): void {
      state.open = payload;
    },
  },
};
