import { ActionContext } from 'vuex';
import invoiceSettingsService from '@/services/invoice-settings.service';
import { InvoiceSettingsInterface } from '@/services/interface/entities/invoice-settings.interface';

export default {
  namespaced: true,
  state: {
    setting: null,
    loading: false,
  },
  getters: {
    settingsLoaded(state:any) {
      return state.setting !== null;
    },
    name(state: any) {
      return `${state.setting.firstname} ${state.setting.lastname}`;
    },
  },
  mutations: {
    SET_LOADING(state: any, payload: boolean): void {
      state.loading = payload;
    },
    SET_SETTING(state: any, payload: InvoiceSettingsInterface): void {
      state.setting = Object.assign(({
        firstname: null,
        lastname: null,
        id: null,
        city: null,
        address: null,
        business: null,
        postalCode: null,
      } as unknown), payload);
    },
    RESET(state: any) {
      state.setting = null;
      state.loading = false;
    },
  },
  actions: {
    async update(context: ActionContext<any, any>, data: any) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const response = await invoiceSettingsService.update(context.rootGetters['auth/authorization'], data);
      context.commit('SET_SETTING', response.data.invoiceSetting);
      context.commit('SET_LOADING', false);
    },
    async init(context: ActionContext<any, any>, force = false) {
      if (!context.rootGetters['auth/isAuth']) return;
      if (!force && context.getters.settingsLoaded) return;
      context.commit('SET_LOADING', true);
      const response = await invoiceSettingsService.fetch(context.rootGetters['auth/authorization']);
      context.commit('SET_SETTING', response.data.invoiceSetting);
      context.commit('SET_LOADING', false);
    },
  },
};
