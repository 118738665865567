import Vue from 'vue';
import Vuex from 'vuex';
import home from './home/home.store';
import topBar from './top-bar/top-bar.store';
import search from './search/search.store';
import shoppingCart from './shopping-cart/shopping-cart.store';
import moduleModal from './module/module-modal.store';
import modulePage from './module/page.store';
import globalModal from './global-modal/global-modal.store';
import platformTexts from './platform-texts/plateform-texts.store';
import platformSkills from './platform-skills/plateform-skills.store';
import tipix from './tipix/tipix.store';
import tipixDetail from './tipix/tipix-detail.store';
// eslint-disable-next-line import/no-cycle
import auth from './auth/auth.store';
import texts from './texts/texts.store';
// eslint-disable-next-line import/no-cycle
import error from './error.store';
import persist from '@/store/_plugins/persist.plugin';
import notifications from '@/store/notifications/notifications.store';
import contact from './contact/contact-form.store';
import homePopup from '@/store/home-popup/home-popup';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connected: true,
    routeLoading: true,
  },
  mutations: {
    SET_ROUTE_LOADING(state, payload) {
      state.routeLoading = !!payload;
    },
  },
  plugins: [
    persist.persistMutations({
      mutations: [
        'auth/SET_TOKEN',
        'auth/account/SET_APP_USER',
      ],
    }),
    persist.importMutations({
      guards: [
        {
          mutation: 'auth/account/SET_APP_USER',
          isValid(value: any) {
            return value && typeof value !== 'string';
          },
        },
      ],
      mutations: [
        'auth/SET_TOKEN',
        'auth/account/SET_APP_USER',
      ],
    }),
    persist.persistState({
      paths: ['shoppingCart.modules'],
    }),
    persist.importState({
      paths: [
        {
          path: 'shoppingCart.modules',
          defaultValue: [],
        },
      ],
    }),
  ],
  modules: {
    error,
    home,
    topBar,
    search,
    shoppingCart,
    moduleModal,
    auth,
    globalModal,
    modulePage,
    notifications,
    platformTexts,
    platformSkills,
    contact,
    tipix,
    tipixDetail,
    texts,
    homePopup,
  },
});
