import Vue from 'vue';

Vue.filter('round', (value: string|number) => {
  if (value === '' || typeof value !== 'number') return value;
  return Math.round(value);
});

Vue.filter('floor', (value: string|number) => {
  if (value === '' || typeof value !== 'number') return 'NaN';
  return Math.floor(value);
});

Vue.filter('ceil', (value: string|number) => {
  if (value === '' || typeof value !== 'number') return 'NaN';
  return Math.ceil(value);
});
