// eslint-disable-next-line import/prefer-default-export
export function deepValue(o: any, path: string, defaultValue?: any): any {
  const split = path.split('.');
  if (split.length === 1) {
    return o[split[0]] || defaultValue;
  }
  if (!o[split[0]] || typeof o[split[0]] !== 'object') {
    return defaultValue;
  }
  return deepValue(o[split[0]], split.slice(1)
    .join('.'), defaultValue);
}

export function deepAssign(o: any, path: string, value: any): any {
  const split = path.split('.');
  if (split.length === 1) {
    // eslint-disable-next-line no-param-reassign
    o[split[0]] = value;
    return o;
  }
  if (!o[split[0]]) {
    // eslint-disable-next-line no-param-reassign
    o[split[0]] = {};
  }
  if (typeof o[split[0]] === 'object') {
    // eslint-disable-next-line no-param-reassign
    o[split[0]] = deepAssign(o[split[0]], split.slice(1).join('.'), value);
  }
  return o;
}
