import { StorageInterface } from '@/_helpers/storage/storage.interface';

class LocalStore implements StorageInterface {
  // eslint-disable-next-line class-methods-use-this
  get(key: string): any {
    const result = localStorage.getItem(key);
    if (!result) return null;
    return JSON.parse(result);
  }

  // eslint-disable-next-line class-methods-use-this
  remove(key: string): void {
    localStorage.removeItem(key);
  }

  // eslint-disable-next-line class-methods-use-this
  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  // eslint-disable-next-line class-methods-use-this
  clear() {
    localStorage.clear();
  }
}

const localStore = new LocalStore();
export default localStore;
