import AppService from '@/services/app.service';
import { RegistrationFormInterface } from '@/store/auth/registration-store.interface';
import { ApiRegistrationInterface } from '@/services/interface/api-registration.interface';

class RegistrationService extends AppService {
  async create(form: RegistrationFormInterface) {
    return this.exec<ApiRegistrationInterface>({
      url: '/v1/registrations',
      method: 'POST',
      data: form,
    });
  }
}

const registrationService = new RegistrationService();
export default registrationService;
