import { ActionContext } from 'vuex';
import registration from './registration.store';
import account from './account.store';
import { AuthStoreInterface } from '@/store/auth/auth-store.interface';
import authService from '@/services/auth.service';
import persist from '../_plugins/persist.plugin';
import { RootStoreInterface } from '@/store/root-store.interface';
import invoiceSettings from './invoice-setting.store';
import modules from './account-module.store';
// eslint-disable-next-line import/no-cycle
import module from './account-module-detail.store';

export default {
  namespaced: true,
  modules: {
    registration,
    account,
    invoiceSettings,
    modules,
    module,
  },
  state: {
    authorization: null,
    loading: false,
    error: '',
  },
  mutations: {
    RESET(state: AuthStoreInterface): void {
      state.authorization = null;
    },
    SET_LOADING(state: AuthStoreInterface, payload: boolean): void {
      state.loading = payload;
    },
    SET_ERROR(state: AuthStoreInterface, payload: string): void {
      state.error = payload;
    },
    SET_TOKEN(state: AuthStoreInterface, payload: string | null): void {
      state.authorization = payload;
    },
  },
  getters: {
    isAuth(state: AuthStoreInterface): boolean {
      return state.authorization !== null;
    },
    authorization(state: AuthStoreInterface): string | null {
      return state.authorization;
    },
  },
  actions: {
    async init(context: ActionContext<AuthStoreInterface, RootStoreInterface>) {
      return Promise.all([
        context.dispatch('texts/init', null, { root: true }),
        context.dispatch('account/getProfile'),
      ]);
    },
    async connect(context: ActionContext<AuthStoreInterface, RootStoreInterface>,
      form: { email: string, password: string }): Promise<void> {
      context.commit('SET_LOADING', true);
      context.commit('invoiceSettings/RESET');
      try {
        const response = await authService.login(form.email, form.password);
        context.commit('SET_TOKEN', response.headers.lodylearn);
        context.commit('account/SET_APP_USER', response.data.appUser);
      } catch (e) {
        context.commit('SET_ERROR', 'Une erreur est survenue, veuillez vérifier vos informations puis réessayer.');
      }
      context.commit('SET_LOADING', false);
    },
    async disconnect(context: ActionContext<AuthStoreInterface, RootStoreInterface>): Promise<void> {
      context.commit('RESET');
      context.commit('account/RESET');
      context.commit('invoiceSettings/RESET');
      persist.clear();
    },
    async sendReset(context: ActionContext<AuthStoreInterface, any>, data: any) {
      context.commit('SET_LOADING', true);
      try {
        await authService.sendReset(data);
        context.commit('SET_LOADING', false);
      } catch (e) {
        context.commit('SET_LOADING', false);
        throw e;
      }
    },
    async reset(context: ActionContext<AuthStoreInterface, any>, data: any) {
      context.commit('SET_LOADING', true);
      try {
        await authService.resetPassword(data);
        context.commit('SET_LOADING', false);
      } catch (e) {
        context.commit('SET_LOADING', false);
        throw e;
      }
    },
  },
};
