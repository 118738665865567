import { InvoiceInterface } from '@/services/interface/entities/invoice.interface';
import accountService from '@/services/account.service';

export default {
  namespaced: true,
  state: {
    invoices: [],
    loading: false,
  },
  mutations: {
    SET_LOADING(state: any, loading: boolean) {
      state.loading = loading;
    },
    SET_INVOICES(state: any, invoices: InvoiceInterface[]) {
      state.invoices = invoices;
    },
  },
  actions: {
    async fetch(context: any) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      accountService.withAuthorization(auth);
      const response = await accountService.invoices();
      context.commit('SET_INVOICES', response.data.invoices);
      context.commit('SET_LOADING', false);
    },
  },
};
