import modulesService from '@/services/modules.service';

export default {
  namespaced: true,
  state: {
    loading: false,
    comment: null,
  },
  mutations: {
    SET_LOADING(state: any, loading: boolean) {
      state.loading = loading;
      if (loading) {
        state.comment = null;
      }
    },
    SET_COMMENT(state: any, comment: any) {
      state.comment = comment;
    },
  },
  actions: {
    async fetch(context: any, moduleId: number) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      const response = await modulesService.fetchOwnedModuleComment(moduleId);
      context.commit('SET_COMMENT', response.data.comment);
      context.commit('SET_LOADING', false);
    },
    async update(context: any, data: { moduleId: number, comment: any }) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      const response = await modulesService.updateOwnedModuleComment(data.moduleId, data.comment);
      context.commit('SET_COMMENT', response.data.comment);
      context.commit('SET_LOADING', false);
    },
    async delete(context: any, moduleId: number) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      const response = await modulesService.updateOwnedModuleComment(moduleId, { delete: true });
      context.commit('SET_COMMENT', response.data.comment);
      context.commit('SET_LOADING', false);
    },
  },
};
