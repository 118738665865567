import modulesService from '@/services/modules.service';

export default {
  namespaced: true,
  state: {
    loading: false,
    faqQuestion: null,
  },
  mutations: {
    SET_LOADING(state: any, loading: boolean) {
      state.loading = loading;
      if (loading) {
        state.faqCcomment = null;
      }
    },
    SET_QUESTION(state: any, faqQuestion: any) {
      state.faqQuestion = faqQuestion;
    },
  },
  actions: {
    async update(context: any, data: { moduleId: number, faqQuestion: any }) {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      const response = await modulesService.updateOwnedModuleFaqQuestion(data.moduleId, data.faqQuestion);
      context.commit('SET_QUESTION', response.data.faqQuestion);
      context.commit('SET_LOADING', false);
    },
  },
};
