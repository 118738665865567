import { ActionContext } from 'vuex';
import { AccountStoreInterface } from '@/store/auth/account-store.interface';
import { AppUserInterface } from '@/services/interface/entities/app-user.interface';
import accountService from '@/services/account.service';
import { RootStoreInterface } from '@/store/root-store.interface';
import invoice from '@/store/auth/account-invoices.store';

export default {
  namespaced: true,
  modules: {
    invoice,
  },
  state: {
    appUser: null,
    loading: false,
  },
  mutations: {
    SET_APP_USER(state: AccountStoreInterface, payload: AppUserInterface): void {
      state.appUser = payload;
    },
    SET_LOADING(state: AccountStoreInterface, payload: boolean): void {
      state.loading = payload;
    },
    RESET(state: AccountStoreInterface): void {
      state.loading = false;
      state.appUser = null;
    },
  },
  getters: {
    name(state: AccountStoreInterface): string | null {
      if (!state.appUser) return null;
      return `${state.appUser.firstname} ${state.appUser.lastname[0]}`;
    },
    firstname(state: AccountStoreInterface): string|null {
      return state.appUser?.firstname || null;
    },
    lastname(state: AccountStoreInterface): string|null {
      return state.appUser?.lastname || null;
    },
  },
  actions: {
    async getProfile(context: ActionContext<AccountStoreInterface, RootStoreInterface>): Promise<void> {
      if (!context.rootGetters['auth/isAuth']) return;
      context.commit('SET_LOADING', true);
      try {
        const response = await accountService.profile(context.rootGetters['auth/authorization']);
        if (!response.data.appUser || response.status !== 200) {
          throw new Error('Invalid auth');
        }
        context.commit('SET_APP_USER', response.data.appUser);
        context.commit('SET_LOADING', false);
      } catch (e) {
        context.commit('SET_LOADING', false);
        await context.dispatch('auth/disconnect', null, { root: true });
      }
    },
    async update(context: ActionContext<AccountStoreInterface, any>, data: any) {
      if (!context.rootGetters['auth/isAuth']) return;
      const o = Object.keys(data).reduce((res: any, current) => {
        // eslint-disable-next-line no-param-reassign
        if (data[current] && data[current] !== '') res[current] = data[current];
        return res;
      }, {});
      if (Object.keys(o).length === 0) return;
      context.commit('SET_LOADING', true);
      try {
        const response = await accountService.update(context.rootGetters['auth/authorization'], o);
        context.commit('SET_APP_USER', response.data.appUser);
        context.commit('SET_LOADING', false);
      } catch (e) {
        context.commit('SET_LOADING', false);
        throw e;
      }
    },
  },
};
