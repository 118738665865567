export default {
  Main: 'Main',
  Home: 'Home',
  CGU: 'CGU',
  Search: 'Search',
  LiveMasterClass: 'LiveMasterClass',
  Platform: 'Platform',
  Offers: 'Offers',
  Contact: 'Contact',
  LogIn: 'LogIn',
  ForgotRequest: 'ForgotRequest',
  ResetPassword: 'ResetPassword',
  SignIn: 'SignIn',
  Payout: 'Payout',
  PaymentValidation: 'PaymentValidation',
  ShoppingCart: 'ShoppingCart',
  Profile: 'Profile',
  ModuleAccount: 'ModuleAccount',
  AccountModuleDetail: 'AccountModuleDetail',
  InvoiceAccount: 'InvoiceAccount',
  ModulePage: 'ModulePage',
  TipiX: 'TipiX',
  TipixDetail: 'TipixDetail',
  ERROR_404: '404',
};
