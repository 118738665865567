import { AxiosResponse } from 'axios';
import AppService from '@/services/app.service';
import ApiConfigHomeInterface from '@/services/interface/api-config-home.interface';

class SiteTextsService extends AppService {
  async fetch(): Promise<AxiosResponse<any>> {
    const config = {
      url: '/v1/site-texts',
    };
    return this.exec<any>(config);
  }
}

const siteTextsService = new SiteTextsService();

export default siteTextsService;
