import { AxiosRequestConfig } from 'axios';
import AppService from '@/services/app.service';
import ApiResponseInterface from '@/services/interface/api-response.interface';

export interface ApiCreateIntentInterface extends ApiResponseInterface {
  added?: boolean,
  intent?: { id: string, clientSecret: string, amount: number }
  modules?: any
}

class OrderService extends AppService {
  async fetch(modules: number[]) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/v1/order/intent',
      data: {
        modules,
      },
    };
    return this.exec<ApiCreateIntentInterface>(config);
  }

  async pay(paymentIntent: string, paymentMethod: string) {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: '/v1/order/intent',
      data: {
        paymentIntent,
        paymentMethod,
      },
    };
    return this.exec<ApiResponseInterface>(config);
  }
}

const orderService = new OrderService();
export default orderService;
