import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { ObserveVisibility } from 'vue-observe-visibility';
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta';

import App from './App.vue';
import router from './router';
import routeNames from './_plugins/route-names.plugin';
import texts from './_plugins/text.plugin';
import store from './store';
import config from '@/config/config';

import './assets/tailwind.css';
import './_filters/round.filter';
import './_filters/duration.filter';
import './_filters/file-size.filter';
import './_filters/moment.filter';
import './_filters/truncate.filter';
// node ^12

const version = '1.0.11.a';
// eslint-disable-next-line max-len
console.log(`%cpowered by Lodylearn%cv${version}`, 'padding: 6px 12px 6px 12px; background: rgb(42, 78, 150); border-top-left-radius: 3px; border-bottom-left-radius: 3px; color: #fff; font-weight: bold', 'padding: 6px 12px 6px 12px; background: #fff; border-top-right-radius: 3px; border-bottom-right-radius: 3px; color: #222; font-weight: bold');
console.log(`%c${process.env.NODE_ENV}`, 'font-weight: bold; color: #00ff33');

Vue.config.productionTip = false;
Vue.use(StripePlugin, {
  pk: config.STRIPE_PUBLISHABLE_KEY,
  apiVersion: config.API_VERSION,
  locale: config.LOCALE,
});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(routeNames);
Vue.use(texts);
Vue.directive('observe-visibility', ObserveVisibility);
Vue.use(VueGtm, {
  id: 'GTM-PG6LV3J',
  router,
});
Vue.use({
  // eslint-disable-next-line no-shadow
  install(Vue: any) {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    Vue.prototype.$gtag = function () { (window as any).dataLayer.push(arguments); }
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  async mounted() {
    const { hash } = window.location;
    if (hash.indexOf('#/module/') === 0) {
      await this.$router.push({ name: 'ModulePage', params: { slug: hash.replace('#/module/', '') } });
    }
    const push = (new URL(window.location as any)).searchParams.get('push');
    if (push === 'email_verified') {
      this.$store.commit('notifications/PUSH_ALERT', {
        message: 'Votre adresse email est maintenant vérifiée',
        timing: 7000,
        type: 'success',
      });
      this.$router.replace({ query: {} });
    }
    if (push === 'email_failed') {
      this.$store.commit('notifications/PUSH_ALERT', {
        message: 'Impossible de vérifier votre adresse. Est-elle déjà vérifiée ?',
        timing: -1,
        type: 'danger',
      });
      this.$router.replace({ query: {} });
    }
    await this.$store.dispatch('auth/init');
    this.$store.dispatch('homePopup/fetch');
  },
}).$mount('#app');
