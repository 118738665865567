import { ActionContext } from 'vuex';
import ModuleInterface from '@/services/interface/entities/module.interface';
import modulesService from '@/services/modules.service';
import ModuleFactory from '@/services/entities/module.factory';
import comment from '@/store/auth/account-comment.store';
import faqQuestion from '@/store/auth/account-faqs-question.store';

export default {
  namespaced: true,
  modules: {
    comment,
    faqQuestion,
  },
  state: {
    modules: [],
    loading: false,
    page: 1,
    limit: 100,
    max: -1,
  },
  mutations: {
    RESET(state: any) {
      state.modules = [];
      state.loading = false;
    },
    SET_MAX(state: any, max: number) {
      state.max = max;
    },
    SET_PAGE(state: any, page: number) {
      state.page = page;
    },
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    ADD_MODULES(state: any, modules: ModuleInterface[]) {
      state.modules = ModuleFactory.buildMany<ModuleInterface>(modules);
    },
  },
  actions: {
    async fetch(context: ActionContext<any, any>) {
      const page = 1;
      if (!context.rootGetters['auth/isAuth']) return;
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      context.commit('SET_LOADING', true);
      context.commit('SET_PAGE', page);
      const { limit } = context.state;
      const moduleResponse = await modulesService.fetchOwnedModules({ page, limit });
      if (moduleResponse.data && moduleResponse.data.modules) {
        context.commit('ADD_MODULES', moduleResponse.data.modules);
      }
      if (moduleResponse.data.pagination) {
        context.commit('SET_MAX', moduleResponse.data.pagination.total);
      }
      context.commit('SET_LOADING', false);
    },
  },
};
