import ModuleInterface from '@/services/interface/entities/module.interface';
import PictureEntity from '@/services/entities/picture.entity';

class ModuleFactory {
  static buildOne<T extends ModuleInterface>(module: T): T {
    // eslint-disable-next-line no-underscore-dangle
    const _module: T = { ...module };
    if (_module.picture) _module.picture = new PictureEntity(_module.picture);
    if (_module.tipixs) {
      _module.tipixs = _module.tipixs.map((person) => ({
        ...person,
        picture: new PictureEntity(person.picture),
      }));
    }
    if (_module.moduleLinks) {
      _module.moduleLinks = _module.moduleLinks.map((ml) => ({
        ...ml,
        module: ModuleFactory.buildOne(ml.module),
      }));
    }
    return _module;
  }

  static buildMany<T extends ModuleInterface>(modules: T[]): T[] {
    return modules.map((module) => ModuleFactory.buildOne(module));
  }
}

export default ModuleFactory;
