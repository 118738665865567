import Vue from 'vue';
import { ActionContext } from 'vuex';
import bar from './search-bar.store';
import { SearchInterface } from '@/store/search/search.interface';
import searchService from '@/services/search.service';
import { ApiSearchInterface } from '@/services/interface/api-search.interface';
import ModuleInterface from '@/services/interface/entities/module.interface';
import { FilterType, LengthFilterInterface } from '@/services/interface/entities/filter.interface';
import ModuleFactory from '@/services/entities/module.factory';

export default {
  namespaced: true,
  modules: {
    bar,
  },
  state: {
    loading: false,
    hasSearched: false,
    filters: [],
    themes: [],
    modules: [],
    sorter: {
      type: null,
      direction: null,
    },
    rules: {
      timing: [],
      price: {
        min: -1,
        max: -1,
      },
      level: null,
      themes: [],
    },
  },
  getters: {
    filterTiming: (state: SearchInterface): FilterType | null => {
      const filters = (state.filters.filter((f: FilterType) => f.type === 'length'));
      return filters.length === 0 ? null : filters[0];
    },
    filteredModules: (state: SearchInterface, getters: { filterTiming: LengthFilterInterface }):
      ModuleInterface[] => state.modules.filter((module: ModuleInterface) => {
      // filtre la durée des modules
      if (state.rules.timing.length > 0) {
        const timingFilter = getters.filterTiming;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const values: number[] = timingFilter.options.map((o) => o.value)
          .filter((v) => typeof v === 'number');
        const limitValue = Math.max(...values);
        if (module.hours > limitValue && state.rules.timing.indexOf('more') === -1) {
          return false;
        }
        if (module.hours <= limitValue && state.rules.timing.indexOf(module.hours) === -1) {
          return false;
        }
      }
      // filtre la difficultée des modules
      if (state.rules.level !== null) {
        if (state.rules.level !== module.difficulty) {
          return false;
        }
      }
      // filtre le prix des modules
      if (state.rules.price.min !== -1 && module.price < state.rules.price.min) {
        return false;
      }
      if (state.rules.price.max !== -1 && module.price > state.rules.price.max) {
        return false;
      }
      // filtre le theme des modules
      const intersect = (array1: number[], array2: { id: number }[]): boolean => array2
        .filter((n) => array1.indexOf(n.id) !== -1).length > 0;
      if (state.rules.themes.length > 0 && (!module.themes || !intersect(state.rules.themes, module.themes))) {
        return false;
      }
      return true;
    })
      .sort((moduleA: ModuleInterface, moduleB: ModuleInterface) => {
        if (state.sorter.type === null || state.sorter.direction === null) return 0;
        const coef = state.sorter.direction === 'asc' ? 1 : -1;
        if (state.sorter.type === 'price') {
          return moduleA.price > moduleB.price ? coef : -coef;
        }
        if (!moduleA.created && !moduleB.created) return 0;
        if (!moduleA.created) return -coef;
        if (!moduleB.created) return coef;
        return moduleA.created > moduleB.created ? coef : -coef;
      }),
  },
  mutations: {
    SET_SORTER(state: SearchInterface, value: { type: 'price' | 'created', direction: 'asc' | 'desc' }): void {
      state.sorter.type = value.type;
      state.sorter.direction = value.direction;
    },
    RESET_SORTER(state: SearchInterface): void {
      state.sorter.type = null;
      state.sorter.direction = null;
    },
    SET_RULES_TIMING(state: SearchInterface, value: number[]): void {
      state.rules.timing = value;
    },
    SET_RULES_PRICES(state: SearchInterface, value: { min: number, max: number }): void {
      state.rules.price = value;
    },
    SET_RULES_LEVEL(state: SearchInterface, value: null | string): void {
      state.rules.level = value;
    },
    SET_RULES_THEMES(state: SearchInterface, value: number[]): void {
      state.rules.themes = value;
    },
    SET_LOADING(state: SearchInterface, value: boolean): void {
      state.loading = value;
    },
    SET_RESULT(state: SearchInterface, value: ApiSearchInterface): void {
      // FIXME fix le bug de non chargement des modules à cause du filtre de prix
      state.rules.price.min = -1;
      state.rules.price.max = -1;
      state.filters = value.filters;
      state.themes = value.themes;
      Vue.set(state, 'modules', ModuleFactory.buildMany(value.modules));
      state.hasSearched = true;
    },
  },
  actions: {
    async search(context: ActionContext<SearchInterface, any>): Promise<void> {
      if (context.state.loading) {
        return;
      }
      context.commit('SET_LOADING', true);
      context.commit('bar/COMMIT');
      try {
        searchService.withAuthorization(context.rootGetters['auth/authorization']);
        const response = await searchService.search(context.state.bar.terms);
        context.commit('SET_RESULT', response.data);
      } catch (e) {
        context.commit('error/ERROR_REQUEST', null, { root: true });
      }
      context.commit('SET_LOADING', false);
      if (context.state.bar.hasChanges) {
        await context.dispatch('search');
      }
    },
  },
};
