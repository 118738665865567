import { ActionContext } from 'vuex';
import HomeStoreInterface from '@/store/home/home-store.interface';
import modulesService from '@/services/modules.service';
import keywordsService from '@/services/keywords.service';
import metricsService from '@/services/metrics.service';
import { ApiKeywordsInterface } from '@/services/interface/api-keywords.interface';
import { ApiMetricsInterface } from '@/services/interface/api-metrics.interface';
import { ApiModulesInterface } from '@/services/interface/api-modules.interface';
import PictureEntity from '@/services/entities/picture.entity';
import ModuleFactory from '@/services/entities/module.factory';
import ModuleInterface from '@/services/interface/entities/module.interface';

export default {
  namespaced: true,
  state: {
    loading: false,
    keywords: [],
    metrics: [],
    modules: [],
    freeModules: [],
  },
  mutations: {
    SET_LOADING(state: HomeStoreInterface, payload: boolean): void {
      state.loading = payload;
    },
    SET_KEYWORDS(state: HomeStoreInterface, payload: ApiKeywordsInterface): void {
      state.keywords = payload.keywords;
    },
    SET_METRICS(state: HomeStoreInterface, payload: ApiMetricsInterface): void {
      state.metrics = payload.metrics;
    },
    SET_FREE_MODULES(state: HomeStoreInterface, payload: ApiModulesInterface): void {
      state.freeModules = ModuleFactory.buildMany<ModuleInterface>(payload.modules);
    },
    SET_MODULES(state: HomeStoreInterface, payload: ApiModulesInterface): void {
      state.modules = ModuleFactory.buildMany<ModuleInterface>(payload.modules);
    },
  },
  actions: {
    async fetchKeywords(context: ActionContext<any, any>) {
      if (context.state.keywords.length > 0) return;
      context.commit('SET_LOADING', true);
      const result = await keywordsService.search();
      context.commit('SET_KEYWORDS', result.data);
      context.commit('SET_LOADING', false);
    },
    async fetch(context: ActionContext<any, any>): Promise<void> {
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      const keywords = await keywordsService.search();
      const freeModules = await modulesService.freeModules();
      const modules = await modulesService.promotedModules();
      context.commit('SET_KEYWORDS', keywords.data);
      context.commit('SET_FREE_MODULES', freeModules.data);
      context.commit('SET_MODULES', modules.data);
      context.commit('SET_LOADING', false);
    },
    async fetchMetrics(context: ActionContext<any, any>) {
      context.commit('SET_LOADING', true);
      const result = await metricsService.fetchMetrics();
      context.commit('SET_METRICS', result.data);
      context.commit('SET_LOADING', false);
    },
  },
};
