import { AlertInterface } from '@/store/notifications/alert.interface';
import uniqueId from '@/_helpers/generator.helper';

export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  mutations: {
    PUSH_ALERT(state: {alerts: AlertInterface[]}, payload: AlertInterface) {
      const obj = Object.assign(payload, { uuid: uniqueId() });
      state.alerts.push(obj);
      if (obj.timing !== -1) {
        setTimeout(() => {
          state.alerts.splice(state.alerts.map((a) => a.uuid).indexOf(obj.uuid), 1);
        }, obj.timing);
      }
    },
    REMOVE_ALERT(state: {alerts: AlertInterface[]}, payload:string|{uuid: string}) {
      const uuid = typeof payload === 'string' ? payload : payload.uuid;
      state.alerts.splice(state.alerts.map((a) => a.uuid).indexOf(uuid), 1);
    },
  },
};
