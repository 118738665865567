import store from '@/store';

function extract(object: any, path: string): any {
  const split = path.split('.');
  const [localPath, nextPath] = [split.slice(0, 1).join('.'), split.slice(1).join('.')];
  if (nextPath) {
    return extract(object[localPath], nextPath);
  }
  return object[localPath];
}

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  install(Vue: any) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$t = (path: string) => {
      const { texts } = (store as any).state.texts;
      return extract(texts, path);
    };
  },
};
