import AppService from '@/services/app.service';
import { ApiConfigSearchInterface } from '@/services/interface/api-config-search.interface';
import { ApiSearchInterface } from '@/services/interface/api-search.interface';

class SearchService extends AppService {
  async config() {
    return this.exec<ApiConfigSearchInterface>({
      url: '/v1/config/search',
    });
  }

  async search(data: { type: 'keyword' | 'term', value: string }[]) {
    return this.exec<ApiSearchInterface>({
      url: '/v1/search',
      method: 'POST',
      data: {
        terms: data,
      },
    });
  }
}

const searchService = new SearchService();
export default searchService;
