import AppService from '@/services/app.service';

class PlatformSkillsService extends AppService {
  async fetch() {
    return this.exec<any>({
      url: '/v1/platform-skills',
      params: {
      },
    });
  }
}

const platformSkillsService = new PlatformSkillsService();
export default platformSkillsService;
