import contactService from '@/services/contact.service';

export default {
  namespaced: true,
  state: {
    loading: false,
    errors: [],
  },
  mutations: {
    SET_LOADING(state: any, loading: boolean) {
      state.loading = loading;
    },
    SET_ERRORS(state: any, errors: string[]) {
      state.errors = errors;
    },
  },
  actions: {
    async send(context: any, data: any) {
      context.commit('SET_LOADING', true);
      try {
        const response = await contactService.send(data);
        context.commit('SET_LOADING', false);
      } catch (e) {
        context.commit('SET_LOADING', false);
        throw e;
      }
    },
  },
};
