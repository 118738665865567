import AppService from '@/services/app.service';
import { ApiKeywordsInterface } from '@/services/interface/api-keywords.interface';

class KeywordsService extends AppService {
  async search() {
    return this.exec<ApiKeywordsInterface>({
      url: '/v1/keywords',
      params: {
        filter: ['search'].join(','),
        page: 1,
        limit: 9,
      },
    });
  }

  async theme() {
    return this.exec<ApiKeywordsInterface>({
      url: '/v1/keywords',
      params: {
        filter: ['theme'].join(','),
        page: 1,
        limit: 9,
      },
    });
  }
}

const keywordsService = new KeywordsService();
export default keywordsService;
