import { AxiosResponse } from 'axios';
import AppService from '@/services/app.service';
import ApiConfigHomeInterface from '@/services/interface/api-config-home.interface';

class HomeService extends AppService {
  async fetchConfig(): Promise<AxiosResponse<ApiConfigHomeInterface>> {
    const config = {
      url: '/v1/config/home',
    };
    return this.exec<ApiConfigHomeInterface>(config);
  }

  async fetchPopupConfig() {
    return this.exec<any>({ url: '/v1/config/popup' });
  }
}

const homeService = new HomeService();

export default homeService;
