import { ActionContext } from 'vuex';
import {
  FieldErrorInterface,
  RegistrationFormInterface,
  RegistrationStoreInterface,
} from '@/store/auth/registration-store.interface';
import registrationService from '@/services/registration.service';
import { RootStoreInterface } from '@/store/root-store.interface';

export default {
  namespaced: true,
  state: {
    loading: false,
    fieldErrors: [],
    form: {
      firstname: '',
      lastname: '',
      email: '',
      captcha: '',
    },
  },
  mutations: {
    SET_LOADING(state: RegistrationStoreInterface, payload: boolean): void {
      state.loading = payload;
    },
    SET_FORM(state: RegistrationStoreInterface, payload: RegistrationFormInterface): void {
      state.form = payload;
    },
    SET_ERRORS(state: RegistrationStoreInterface, payload: FieldErrorInterface[]): void {
      state.fieldErrors = payload;
    },
    RESET(state: RegistrationStoreInterface): void {
      state.fieldErrors = [];
      state.form = {
        firstname: '',
        lastname: '',
        email: '',
        captcha: '',
        password: '',
        handicap: false,
        specificNeed: false,
      };
    },
  },
  actions: {
    async send(context: ActionContext<RegistrationStoreInterface, RootStoreInterface>,
      values: RegistrationFormInterface): Promise<void> {
      context.commit('SET_LOADING', true);
      context.commit('SET_FORM', values);
      try {
        const response = await registrationService.create(values);
        if (response.data) {
          context.commit('RESET');
        }
      } catch (e) {
        context.commit('SET_ERRORS', [
          {
            field: 'all',
            message: 'Une erreur est survenue. Ceci peut être dû à un mail déjà utilisé ou à une '
              + 'erreur de notre serveur. Veuillez vérifier vos informations puis réessayer',
          },
        ]);
        context.commit('SET_LOADING', false);
        throw e;
      }
      context.commit('SET_LOADING', false);
    },
  },
};
