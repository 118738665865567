import AppService from '@/services/app.service';
import { ApiInvoiceSettingsInterface } from '@/services/interface/api-invoice-settings.interface';

class InvoiceSettingsService extends AppService {
  async update(token: string|null, data: any) {
    this.withAuthorization(token);
    return this.exec<ApiInvoiceSettingsInterface>({
      url: '/v1/invoice-settings',
      method: 'POST',
      data,
    });
  }

  async fetch(token: string|null) {
    this.withAuthorization(token);
    return this.exec<ApiInvoiceSettingsInterface>({
      url: '/v1/invoice-settings',
      method: 'GET',
    });
  }
}

const invoiceSettingsService = new InvoiceSettingsService();
export default invoiceSettingsService;
