import Vue from 'vue';

Vue.filter('duration', (_value: number|string) => {
  let value = _value;
  if (typeof value === 'string') value = parseInt(value, 0);
  if (Number.isNaN(value)) return '0 sec';
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor(value / 60) % 60;
  const seconds = value % 60;
  if (hours > 0) {
    return `${hours} h`;
  }
  if (minutes > 0) {
    return `${minutes} min`;
  }
  return `${seconds} sec`;
});
