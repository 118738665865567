import AppService from '@/services/app.service';
import { ApiLoginInterface } from '@/services/interface/api-login.interface';

class AuthService extends AppService {
  async login(email: string, password: string) {
    return this.exec<ApiLoginInterface>({
      url: '/v1/auth',
      method: 'POST',
      data: {
        email,
        password,
      },
    });
  }

  async sendReset(data: any) {
    return this.exec<any>({
      url: '/v1/auth/reset',
      method: 'POST',
      data,
    });
  }

  async resetPassword(data: any) {
    return this.exec<any>({
      url: '/v1/auth/reset',
      method: 'PUT',
      data,
    });
  }
}

const authService = new AuthService();
export default authService;
