import AppService from '@/services/app.service';
import { ApiModuleDetailInterface } from '@/services/interface/api-module-detail.interface';
import { ApiModulesInterface } from '@/services/interface/api-modules.interface';
import ApiResponseInterface from '@/services/interface/api-response.interface';
import { ApiModuleCommentInterface } from '@/services/interface/api-module-comment.interface';
import { ApiModuleFaqQuestionInterface } from '@/services/interface/api-module-faq-question.interface';

class ModulesService extends AppService {
  async fetchOne(id: number) {
    return this.exec<ApiModuleDetailInterface>({
      url: `/v1/modules/${id}`,
      method: 'GET',
    });
  }

  async fetchOneBySlug(slug: string) {
    return this.exec<ApiModuleDetailInterface>({
      url: '/v1/modules',
      params: {
        slug,
      },
      method: 'GET',
    });
  }

  async fetchModuleByIds(ids: number[], filter?: string[]) {
    return this.fetchModules(filter || [], { ids });
  }

  async freeModules() {
    return this.fetchModules(['promoted,free'], { page: 1, limit: 9 });
  }

  async promotedModules() {
    return this.exec<ApiModulesInterface>({
      url: '/v1/modules',
      params: {
        filter: 'promoted,withPrice',
        page: 1,
        limit: 9,
      },
    });
  }

  async fetchOwnedModules(pagination: { page: number, limit: number }) {
    return this.exec<ApiModulesInterface>({
      url: '/v1/accounts-modules',
      method: 'GET',
      params: pagination,
    });
  }

  async fetchOwnedModule(id: number) {
    return this.exec<ApiModuleDetailInterface>({
      url: `/v1/accounts-modules/${id}`,
      method: 'GET',
    });
  }

  async fetchOwnedModuleComment(id: number) {
    return this.exec<ApiModuleCommentInterface>({
      url: `/v1/accounts-modules/${id}/comment`,
      method: 'GET',
    });
  }

  async updateOwnedModuleComment(id: number, comment: any) {
    return this.exec<ApiModuleCommentInterface>({
      url: `/v1/accounts-modules/${id}/comment`,
      method: 'POST',
      data: comment,
    });
  }

  async updateOwnedModuleFaqQuestion(id: number, faqQuestion: any) {
    return this.exec<ApiModuleFaqQuestionInterface>({
      url: `/v1/accounts-modules/${id}/faq-question`,
      method: 'POST',
      data: faqQuestion,
    });
  }

  async updateOwnedModuleProgress(id: number, progress: any) {
    return this.exec<ApiResponseInterface & { saved?: boolean }>({
      url: `/v1/accounts-modules/set-current-progress/${id}`,
      method: 'PUT',
      data: {
        progress,
      },
    });
  }

  fetchModules(filters: string[], params: any = {}) {
    return this.exec<ApiModulesInterface>({
      url: '/v1/modules',
      params: { filter: filters.join(','), ...params },
    });
  }
}

const modulesService = new ModulesService();
export default modulesService;
