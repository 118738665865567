import tipixService from '@/services/tipixs.service';
import PictureEntity from '@/services/entities/picture.entity';
import ModuleFactory from '@/services/entities/module.factory';
import ModuleInterface from '@/services/interface/entities/module.interface';

export default {
  namespaced: true,
  state: {
    loadings: false,
    tipix: null,
  },
  mutations: {
    SET_LOADING(state: any, value: boolean) {
      state.loading = value;
    },
    SET_TIPIX(state: any, tipix: any) {
      state.tipix = {
        ...tipix,
        modules: ModuleFactory.buildMany<ModuleInterface>(tipix.modules),
        tipixPictures: tipix.tipixPictures.map((tp: any) => ({
          ...tp,
          picture: new PictureEntity(tp.picture),
        })),
      };
    },
  },
  actions: {
    async fetch(context: any, id: any) {
      context.commit('SET_LOADING', true);
      const auth = context.rootGetters['auth/authorization'];
      tipixService.withAuthorization(auth);
      const tipixResponse = await tipixService.fetchTipix(id);
      const { tipix } = tipixResponse.data as any;
      context.commit('SET_TIPIX', tipix);
      context.commit('SET_LOADING', false);
    },
  },
};
