import {
  ArticleStoriesInterface,
  FileResourcesInterface, PodcastResourcesInterface,
  SrcGroupsInterface,
  SrcQuizInterface,
  TextResourcesInterface,
  VideoResourcesInterface,
} from '@/_helpers/bin/resource-group-model.interafces';
import {
  FolderResource,
  GroupResource,
  GroupResourceColorEnum,
  PdfResource, PodcastResouce,
  VideoResource,
} from '@/_helpers/bin/resource-group.interfaces';
import { resolve } from '@/_helpers/image.helper';

export const THEME_CONFIG = {
  GO: {
    color: GroupResourceColorEnum.TIP_JAUNE,
    icon: resolve('symbole/Symbole__Atelier.png'),
  },
  TIP: {
    icon: resolve('symbole/Symbole__Cours.png'),
    color: GroupResourceColorEnum.TIP_CORAIL,
  },
  FAST: {
    icon: resolve('symbole/Symbole__Capsule.png'),
    color: GroupResourceColorEnum.TIP_ORANGE,
  },
  QUIZ: {
    icon: resolve('symbole/Symbole__Test.png'),
    color: GroupResourceColorEnum.TIP_VERT,
  },
  MEET: {
    icon: resolve('symbole/Symbole__Rdv.svg'),
    color: GroupResourceColorEnum.TIP_ROSE,
  },
  NEWS: {
    icon: resolve('symbole/Symbole__Extras.png'),
    color: GroupResourceColorEnum.TIP_BLEU,
  },
  FAQ: {
    icon: resolve('symbole/Symbole__Aide.png'),
    color: GroupResourceColorEnum.TIP_VIOLET,
  },
  STORY: {
    icon: resolve('symbole/Symbole__Breve.png'),
    color: GroupResourceColorEnum.TIP_BLEU2,
  },
};

function srcLoad(name: string) {
  return resolve(`module-resources/Icone-Ressources_${name}_blanc.svg`);
}

export const SRC_GROUP_THEME = {
  Dossier: srcLoad('Dossier'),
  Excel: srcLoad('Excel'),
  Image: srcLoad('Image'),
  PDF: srcLoad('PDF'),
  Podcast: srcLoad('Podcast'),
  PPT: srcLoad('PPT'),
  Quiz: srcLoad('Quiz'),
  Video: srcLoad('Video'),
  Word: srcLoad('Word'),
  PRESET_VIDEO: resolve('resources/Icone-Ressources_Video_blanc.svg'),
  PRESET_FOLDER: resolve('resources/Icone-Ressources_Dossier_blanc.svg'),
  PRESET_PDF: resolve('resources/Icone-Ressources_PDF_blanc.svg'),
  PRESET_QUIZ: resolve('resources/Icone-Ressources_Quiz_blanc.svg'),
  PRESET_STORY: resolve('resources/Icone-Ressources_Podcast_blanc.svg'),
};

function handleResourceGroupChild(moduleId: number, srcGroupId: number, progress: {[k:string]: {[k:string]: any}}): (
  child: (FileResourcesInterface | VideoResourcesInterface | TextResourcesInterface | PodcastResourcesInterface)
) => (FolderResource | PdfResource | VideoResource | PodcastResouce | null) {
  return (
    child: FileResourcesInterface | VideoResourcesInterface | TextResourcesInterface | PodcastResourcesInterface,
  ): FolderResource | PdfResource | VideoResource | PodcastResouce | null => {
    function isRead(type: string, id: number) {
      return !(!progress || !progress[type] || !progress[type][id] || !progress[type][id].ended);
    }
    if (child.type === 'PODCAST') {
      let meta: any;
      let time: number|null = null;
      const stored = progress && progress.PODCAST && progress.PODCAST[child.id] ? progress.PODCAST[child.id] : null;
      if (stored && stored.audio && stored.audio.time) {
        time = stored.audio.time;
        meta = {
          audio: {
            time,
            ended: stored.audio.ended || false,
          },
        };
      }
      return {
        id: child.id,
        moduleId,
        srcGroupId,
        meta,
        open: false,
        title: child.title,
        checked: meta && meta.audio && meta.audio.ended,
        type: 'PODCAST',
        content: child.description,
        selected: false,
        logo: child.icon ? SRC_GROUP_THEME[child.icon] : SRC_GROUP_THEME.PRESET_STORY,
        source: {
          sound: child.path,
          thumbnail: child.thumbnailUrl,
        },
      } as PodcastResouce;
    }
    if (child.type === 'TEXT') {
      return {
        id: child.id,
        moduleId,
        srcGroupId,
        open: false,
        title: child.title,
        checked: isRead('FOLDER', child.id),
        type: 'FOLDER',
        content: child.description,
        selected: false,
        logo: child.icon ? SRC_GROUP_THEME[child.icon] : SRC_GROUP_THEME.PRESET_FOLDER,
      } as FolderResource;
    }
    if (child.type === 'VIDEO') {
      let meta: any;
      let time: number|null = null;
      if (progress && progress.VIDEO && progress.VIDEO[child.id] && progress.VIDEO[child.id].time) {
        time = progress.VIDEO[child.id].time;
        meta = {
          video: {
            time,
          },
        };
      }
      const video = {
        meta,
        id: child.id,
        moduleId,
        srcGroupId,
        open: false,
        checked: false,
        selected: false,
        title: child.title,
        content: child.description,
        length: child.videoParts.reduce((max, current) => Math.max(max, current.toFrame), 0),
        logo: child.icon ? SRC_GROUP_THEME[child.icon] : SRC_GROUP_THEME.PRESET_VIDEO,
        type: child.type,
        source: {
          m3u8: child.embedUrl,
          thumbnail: child.thumbnailUrl,
          subtitles: child.subtitlesUrl,
          chapters: child.videoParts.map((videoPart) => ({
            title: videoPart.title,
            index: videoPart.id,
            start: videoPart.fromFrame,
            end: videoPart.toFrame,
          })),
        },
        children: child.videoParts.map((videoPart) => ({
          id: videoPart.id,
          title: videoPart.title,
          checked: time && (time >= videoPart.fromFrame),
          current: false,
          userInteraction: false,
          setChapter: videoPart.id,
          length: videoPart.toFrame - videoPart.fromFrame,
        })),
      } as VideoResource;
      if (video.children.length === 0 && time !== null) {
        video.checked = true;
        return video;
      }
      video.checked = video.children.reduce((checked: any, item) => (checked && item.checked), true);
      return video;
    }
    if (child.type === 'FILE') {
      return {
        id: child.id,
        moduleId,
        srcGroupId,
        open: false,
        title: child.title,
        checked: isRead('PDF', child.id),
        type: 'PDF',
        content: child.description,
        selected: false,
        size: child.size,
        logo: child.icon ? SRC_GROUP_THEME[child.icon] : SRC_GROUP_THEME.PRESET_PDF,
        thumbnail: child.thumbnailUrl,
        filePath: child.filePath,
      } as PdfResource;
    }
    return null;
  };
}

// eslint-disable-next-line import/prefer-default-export
export function resourceGroupToTree(
  resourceGroup: SrcGroupsInterface, progress?:{srcGroup?:{[k:string]:{[k:string]:any}}},
): GroupResource {
  // state.progress.srcGroup[data.srcGroupId][data.type][data.resourceId]
  // FIXME n'ajoute pas les sub item (apres la video pour tip par exemple ?)
  let localProgress: any;
  if (progress && progress.srcGroup && progress.srcGroup[resourceGroup.id]) {
    localProgress = progress.srcGroup[resourceGroup.id];
  }
  return {
    id: `group__${resourceGroup.id}`,
    color: THEME_CONFIG[resourceGroup.icon].color,
    icon: THEME_CONFIG[resourceGroup.icon].icon,
    resourceCount: resourceGroup.resources.length,
    title: resourceGroup.title,
    displayOrder: resourceGroup.displayOrder,
    open: false,
    children: resourceGroup.resources
      .map(handleResourceGroupChild(resourceGroup.moduleId, resourceGroup.id, localProgress))
      .filter((s) => (s !== null)) as (FolderResource | PdfResource | VideoResource | PodcastResouce)[],
  };
}

export function resourceMeetForTreeFactory(meets: any[]): GroupResource | null {
  if (meets.length === 0) return null;
  return {
    id: 'meet__0',
    icon: THEME_CONFIG.MEET.icon,
    color: THEME_CONFIG.MEET.color,
    resourceCount: 0,
    open: false,
    displayOrder: meets[0].displayOrder,
    title: meets[0].title,
    altTitle: meets[0].subtitle,
    usedInProgress: false,
    children: [],
    behavior: 'open',
    href: meets[0].calendlyUrl,
  };
}

export function resourceStoryForTreeFactory(stories: any[]): GroupResource[] {
  return stories.map((story) => ({
    id: `story__${story.id}`,
    icon: THEME_CONFIG.STORY.icon,
    color: THEME_CONFIG.STORY.color,
    resourceCount: story.articleStories.length,
    open: false,
    displayOrder: story.displayOrder,
    title: 'Retours d\'expérience',
    usedInProgress: false,
    children: story.articleStories.map((article: ArticleStoriesInterface) => ({
      id: article.id,
      moduleId: story.moduleId,
      parent: story.id,
      open: false,
      title: article.title,
      url: article.url,
      checked: false,
      type: 'STORY',
      content: '',
      selected: false,
      hasCheck: false,
      logo: SRC_GROUP_THEME.PRESET_STORY,
    })),
  }));
}

export function resourceQuizToTree(resourceQuiz: SrcQuizInterface, progressStore?: { quiz?: { [k: string]: any } })
  : GroupResource {
  let meta: any;
  if (progressStore && progressStore.quiz && progressStore.quiz[resourceQuiz.id]) {
    meta = {
      quiz: progressStore.quiz[resourceQuiz.id],
    };
  }

  function getStore<T>(key: string): (T | undefined) {
    if (progressStore && progressStore.quiz && progressStore.quiz[resourceQuiz.id]) {
      return progressStore.quiz[resourceQuiz.id][key];
    }
    return undefined;
  }

  return {
    id: `quiz__${resourceQuiz.id}`,
    icon: THEME_CONFIG.QUIZ.icon,
    color: THEME_CONFIG.QUIZ.color,
    resourceCount: 1,
    open: false,
    displayOrder: resourceQuiz.displayOrder,
    title: resourceQuiz.title,
    children: [
      {
        meta,
        id: resourceQuiz.id,
        parent: resourceQuiz.moduleId,
        open: false,
        title: resourceQuiz.title,
        checked: (getStore('ended') !== undefined) ? (!!getStore('ended')) : false,
        type: 'QUIZ',
        logo: resourceQuiz.icon ? SRC_GROUP_THEME[resourceQuiz.icon] : SRC_GROUP_THEME.PRESET_QUIZ,
        selected: false,
        content: resourceQuiz.description,
        quiz: resourceQuiz.quizQuestions.map((question) => ({
          question: question.question,
          responses: question.quizResponses.map((response) => ({
            label: response.value,
            isCorrect: response.points > 0,
            color: response.color,
          })),
          upstream: resourceQuiz.upstream,
        })),
      },
    ],
  };
}
