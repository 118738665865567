import AppService from '@/services/app.service';
import { ApiProfileInterface } from '@/services/interface/api-profile.interface';
import { ApiInvoicesInterface } from '@/services/interface/api-invoices.interface';

class AccountService extends AppService {
  async profile(token: string) {
    this.withAuthorization(token);
    return this.exec<ApiProfileInterface>({
      url: '/v1/accounts',
    });
  }

  async update(token: string, data: any) {
    this.withAuthorization(token);
    return this.exec<ApiProfileInterface>({
      url: '/v1/accounts',
      method: 'PUT',
      data,
    });
  }

  async invoices() {
    return this.exec<ApiInvoicesInterface>({
      url: '/v1/accounts-invoices',
      method: 'GET',
    });
  }
}

const accountService = new AccountService();
export default accountService;
