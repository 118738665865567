import { ActionContext } from 'vuex';
import modal from './modal';
import modulesService from '@/services/modules.service';
import intent from './intent';
import ModuleFactory from '@/services/entities/module.factory';
import homeService from '@/services/home.service';
import ModuleInterface from '@/services/interface/entities/module.interface';

export default {
  namespaced: true,
  state: {
    modules: [],
    fetchedModules: [],
    loading: false,
    interestingModules: [],
  },
  modules: {
    modal,
    intent,
  },
  getters: {
    articleCount(state: any) {
      return state.modules.length;
    },
    currentModules(state: any) {
      return state.fetchedModules.filter((module: any) => state.modules.indexOf(module.id) !== -1);
    },
  },
  mutations: {
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    SET_FETCHED(state: any, payload: any) {
      state.fetchedModules = ModuleFactory.buildMany(payload);
      const ids = payload.map((module: any) => module.id);
      state.modules = state.modules.filter((m: any) => ids.indexOf(m) !== -1);
    },
    RESET(state: any) {
      state.modules = [];
    },
    ADD_MODULE(state: any, payload: any) {
      if (state.modules.indexOf(payload) !== -1) return;
      state.modules.push(payload);
      state.fetchedModules = [];
    },
    REMOVE_MODULE(state: any, id: any) {
      state.modules = state.modules.filter((m: any) => m !== id);
    },
    SET_INTERESTING_MODULES(state: any, payload: any) {
      state.interestingModules = ModuleFactory.buildMany<ModuleInterface>(payload);
    },
  },
  actions: {
    async fetch(context: ActionContext<any, any>) {
      if (context.state.loading) return;
      if (context.state.modules.length === 0) {
        context.commit('SET_FETCHED', []);
        return;
      }
      context.commit('SET_LOADING', true);
      try {
        modulesService.withAuthorization(context.rootGetters['auth/authorization']);
        const response = await modulesService.fetchModuleByIds(context.state.modules, ['notPaid']);
        context.commit('SET_FETCHED', response.data.modules);
      } catch (e) {
        context.commit('RESET');
      }
      context.commit('SET_LOADING', false);
    },
    async fetchInteresting(context: ActionContext<any, any>) {
      const response = await modulesService.fetchModules(['notPaid', 'promoted', 'withPrice']);
      context.commit('SET_INTERESTING_MODULES', response.data.modules);
    },
  },
};
