import config from '@/config/config';

const domain = config.IMAGE_DOMAIN;
const images = `${domain}/assets/images/`;

export function resolve(path: string): string {
  return `${images}${path}`;
}

export async function preloadImage(imageAbsolutePath: string) {
  return new Promise((_resolve, _reject) => {
    const img = new Image();
    img.onerror = _reject;
    img.onload = () => {
      _resolve(img);
    };
    img.src = imageAbsolutePath;
  });
}
