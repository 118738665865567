

























import { Component, Vue } from 'vue-property-decorator';
import './assets/style/index.scss';
import { mapState } from 'vuex';
// import ModalHandicap from "@/components/global-modals/modal-handicap.vue";
// import ModalAccessibility from "@/components/global-modals/modal-accessibility.vue";
// import ModalPrivacy from "@/components/global-modals/modal-privacy.vue";

@Component({
  components: {
    ModalPrivacy: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-privacy.vue'),
    ModalLegals: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-legals.vue'),
    ModalCgu: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-cgu.vue'),
    ModalCgv: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-cgv.vue'),
    ModalHandicap: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-handicap.vue'),
    ModalAccessibility: () => import(/* webpackPrefetch: true */ '@/components/global-modals/modal-accessibility.vue'),
  },
  computed: {
    ...mapState({
      routeLoading: (s: any) => s.routeLoading,
    }),
  },
})
export default class App extends Vue {}
