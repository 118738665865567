import siteTextsService from '@/services/site-texts.service';

export default {
  namespaced: true,
  state: {
    texts: null,
  },
  mutations: {
    SET_TEXTS(state: any, texts: any) {
      state.texts = texts;
    },
  },
  actions: {
    async init(context: any) {
      const response = await siteTextsService.fetch();
      context.commit('SET_TEXTS', response.data);
    },
  },
};
