import AppService from '@/services/app.service';
import { ApiMetricsInterface } from '@/services/interface/api-metrics.interface';

class MetricsService extends AppService {
  async fetchMetrics() {
    return this.exec<ApiMetricsInterface>({
      url: '/v1/metrics',
      params: {
      },
    });
  }
}

const metricsService = new MetricsService();
export default metricsService;
