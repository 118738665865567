import { Route } from 'vue-router';
import ROUTE from '../collection';

export default (store: any) => (
  async (to: Route, from: Route | null, next: (params?:any) => void): Promise<void> => {
    if (!store.getters['auth/isAuth']) {
      next({ name: ROUTE.LogIn, params: { redirect: to } });
      return;
    }
    await store.dispatch('auth/account/getProfile');
    if (!store.getters['auth/isAuth']) {
      next({ name: ROUTE.LogIn, params: { redirect: to } });
      return;
    }
    next();
  }
);
