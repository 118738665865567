import prod from '@/config/config.prod';
import dev from '@/config/config.dev';

export interface AppConfig {
  API_URL: string,
  IMAGE_DOMAIN: string,
  STRIPE_PUBLISHABLE_KEY: string,
  API_VERSION: string,
  LOCALE: string,
  CHECKOUT_PAGE: string,
}

const tmp = process.env.NODE_ENV === 'production' ? prod : dev;
export default {
  RECAPTCHA_KEY: '6LcvmdocAAAAAEpDHL7maiff2jiQCIHOLHaunxJm',
  CHECKOUT_PAGE: 'http://localhost:8080/paiement/run',
  ...tmp,
} as AppConfig;
