import AppService from '@/services/app.service';

class GlobalTextsService extends AppService {
  async fetch(key: string) {
    return this.exec<any>({
      url: '/v1/global-texts',
      params: {
        type: key,
      },
    });
  }
}

const globalTextsService = new GlobalTextsService();
export default globalTextsService;
