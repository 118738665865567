import { PictureInterface } from '@/services/interface/entities/module.interface';

function isCdnURL(url: string) {
  // eslint-disable-next-line max-len
  return url.indexOf('admin-lodylearn/medias') !== -1 || url.indexOf('lodylearn.ubg-interactive.com/admin/medias') !== -1;
}

class PictureEntity implements PictureInterface {
  picture: PictureInterface;

  constructor(picture: PictureInterface) {
    this.picture = picture;
  }

  get path() {
    return this.picture.path;
  }

  get absolutePath() {
    return this.picture.absolutePath;
  }

  get id() {
    return this.picture.id;
  }

  getPath(options?: { w?: number; h?: number; crop?: boolean }): string {
    if (this.absolutePath && isCdnURL(this.absolutePath)) {
      const url = new URL(this.absolutePath);
      const params = url.searchParams;
      if (options && options.w) params.append('w', `${options.w}`);
      if (options && options.h) params.append('h', `${options.h}`);
      if (options && options.crop) params.append('crop-to-fit', '1');
      return url.toString();
    }
    return this.absolutePath;
  }
}

export default PictureEntity;
