import tipixService from '@/services/tipixs.service';
import PictureEntity from '@/services/entities/picture.entity';

export default {
  namespaced: true,
  state: {
    loadings: false,
    sortKey: 'created',
    sortDirection: 'desc',
    tipixs: [],
    keywords: [],
  },
  getters: {
    sortedTipixs: (state: any) => state.tipixs.filter((tipix: any) => {
      const checkedIds = state.keywords.filter((keyword: any) => keyword.checked).map((keyword: any) => keyword.id);
      if (checkedIds.length === 0) return true;
      return tipix.keywords
        .reduce((result: boolean, keyword: any) => (result || checkedIds.indexOf(keyword.id) !== -1), false);
    }).sort((a: any, b: any) => {
      const coef = state.sortDirection === 'desc' ? -1 : 1;
      if (state.sortKey === 'created') {
        return a.created > b.created ? coef : -1 * coef;
      }
      if (state.sortKey === 'modulesCount') {
        return a.modulesCount > b.modulesCount ? coef : -1 * coef;
      }
      if (state.sortKey === 'soldCount') {
        return a.soldCount > b.soldCount ? coef : -1 * coef;
      }
      return 0;
    }),
  },
  mutations: {
    SET_LOADING(state: any, value: boolean) {
      state.loading = value;
    },
    SET_SORTER_KEY(state: any, key: string) {
      state.sortKey = key;
    },
    SET_SORTER_DIRECTION(state: any, value: string) {
      state.sortDirection = value;
    },
    SET_TIPIXS(state: any, tipixs: any) {
      state.tipixs = tipixs;
      const keywords = [].concat(...tipixs.map((t: any) => t.keywords));
      const ids = keywords.map((k: any) => k.id);
      state.keywords = keywords.filter((k: any, i) => ids.indexOf(k.id) === i)
        .map((item: any) => ({ ...item, checked: false }));
    },
    TOGGLE_KEYWORD(state: any, id: any) {
      state.keywords = state.keywords.map((keyword: any) => {
        if (keyword.id !== id) return keyword;
        return { ...keyword, checked: !keyword.checked };
      });
    },
    RESET(state: any) {
      state.tipixs = [];
      state.keywords = [];
    },
  },
  actions: {
    async fetch(context: any) {
      context.commit('SET_LOADING', true);
      const tipixResponse = await tipixService.fetchAll();
      const { tipixs } = tipixResponse.data as any;
      context.commit('SET_TIPIXS', tipixs.map((item: any) => ({ ...item, picture: new PictureEntity(item.picture) })));
      context.commit('SET_LOADING', false);
    },
  },
};
