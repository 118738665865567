/* eslint-disable */
import { ActionContext } from 'vuex';
import ModuleDetailInterface from '@/services/interface/entities/module-detail.interface';
import modulesService from '@/services/modules.service';
import ModuleFactory from '@/services/entities/module.factory';
import {
  resourceGroupToTree,
  resourceMeetForTreeFactory,
  resourceQuizToTree, resourceStoryForTreeFactory
} from '@/_helpers/bin/resource-group.converter';
import { GroupResource } from '@/_helpers/bin/resource-group.interfaces';
import {alertDanger, alertInfo, alertSuccess} from '@/_helpers/alert.helper';


const CAN_TOGGLE_RESOURCE_STATE = false;
const CAN_TOGGLE_CHAPTER_STATE = false;
const AUTO_CLOSE_RESOURCE_GROUP_WHEN_ENDED = false;

function isResourceChecked(resource: any) {
  if (resource.type === 'QUIZ' && !resource.checked) return false;
  if (resource.type === 'PODCAST') return resource.meta && resource.meta.podcast && resource.meta.podcast.ended;
  if (resource.children === undefined) return true;
  return resource.children.reduce((checked: boolean, child: { checked: boolean }) => (checked && child.checked), true);
}

function setCurrentResource(state: any, resource: any) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  state.currentResource = null;
  // open resource group
  const resourceGroup = state.resources
    .filter((src: any) => src.children.map((rs: any) => rs.id)
      .indexOf(resource.id) !== -1)
    .pop();
  if (resourceGroup !== undefined) {
    resourceGroup.open = true;
  }

  // reset all select
  state.resources.forEach((src: any) => {
    src.children.forEach((_resource: any) => {
      _resource.selected = false;
      if (_resource.children !== undefined) {
        _resource.children.forEach((_child: any) => {
          _child.current = false;
          _child.selected = false;
        });
      }
    });
    src.selected = false;
  });

  // set current resource & open state
  state.currentResource = resource;
  state.currentResource.open = true;
  if (!state.currentResource.child && resource.type === 'VIDEO') {
    state.currentResource.child = resource.children[0];
  }

  if (state.currentResource.child) {
    state.currentResource.child.checked = true;
    state.currentResource.child.selected = true;
    state.currentResource.child.current = true;
  }
  state.currentResource.checked = isResourceChecked(state.currentResource);
  state.currentResource.selected = true;
  state.sideMenuopenState = false;
  document.body.classList.remove('no-scroll-mobile');

  if (AUTO_CLOSE_RESOURCE_GROUP_WHEN_ENDED) {
    const allChecked = resourceGroup.children.reduce((checked: boolean, child: any) => {
      return checked && child.checked;
    }, true);
    if (resourceGroup && resourceGroup.children && allChecked) {
      resourceGroup.open = false;
      // open next
      state.resources.forEach((resource: any, index: number) => {
        if (resource.id === resourceGroup.id && state.resources[index + 1] !== undefined) {
          state.resources[index + 1].open = true;
        }
      });
    }
  }
}

function getResourceFromChapter(state: any, chapter: any) {
  return state.resources.reduce((result: any, group: any) => {
    if (group.children.length === 0 || result) return result;
    return group.children.reduce((_result: any, _resource: any) => {
      if (_result || _resource.children === undefined) return _result;
      if (_resource.children.map((c: any) => c.id)
        .indexOf(chapter.id) !== -1) {
        return _resource;
      }
      return _result;
    }, null);
  }, null);
}

function flatModuleList(state: any) {
  return [].concat([], ...state.resources.map((resource: any) => (resource.children)));
}

function atLeastOneQuizIsEnded(state: any)  {
  let endeds: boolean[] =  [];
  const progress = state.progress.quiz;
  if  (progress != null) {
    Object.keys(progress).forEach(function(key)  {
      endeds.push(progress[key].ended);
    });
    return endeds.includes(true) ? true : false;
  }else {
    return false;
  }
}


function upStreamQuizIsEnded(state: any)  {
  const progress = state.progress.quiz;
  let res = false
  if  (progress != null) {
    Object.keys(progress).forEach(function(key) {
      if(progress[key].ended && progress[key].upstream) {
        res = true;
      };
    });
    return res;
  }
}

function downStreamQuizIsEnded(state: any)  {
  const progress = state.progress.quiz;
  let res = false
  if  (progress != null) {
    Object.keys(progress).forEach(function(key) {
      if(progress[key].ended && !progress[key].upstream) {
        res = true;
      };
    });
    return res;
  }
}




export default {
  namespaced: true,
  state: {
    loading: false,
    module: null,
    resources: [],
    currentResource: null,
    progress: {},
    sideMenuopenState: false,
  },
  mutations: {
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    SET_MODULE(state: any, payload: ModuleDetailInterface) {
      state.module = ModuleFactory.buildOne<ModuleDetailInterface>(payload);
      state.progress = state.module.progress || {};
      state.resources = state.module.srcGroups.map((item: any) => resourceGroupToTree(item, state.progress));
      if (state.module.srcQuiz.length > 0) {
        state.resources.push(...state.module.srcQuiz.map((quiz: any) => resourceQuizToTree(quiz, state.progress)));
      }
      if (state.module.srcStories.length > 0) {
        state.resources.push(...resourceStoryForTreeFactory(state.module.srcStories));
      }
      state.resources.push(resourceMeetForTreeFactory(state.module.srcMeets));
      state.resources = state.resources.filter((b: any) => !!b)
        .sort((ra: any, rb: any) => ra.displayOrder - rb.displayOrder);
      if (state.resources.length === 0) return;
      for (let i = 0; i < state.resources.length; i++) {
        if (state.resources[i].children.length === 0) continue;
        return setCurrentResource(state, state.resources[i].children[0]);
      }
    },
    SET_CURRENT_RESOURCE(state: any, payload: any) {
      setCurrentResource(state, payload);
    },
    SET_CURRENT_CHAPTER(state: any, chapter: any) {
      const resource = getResourceFromChapter(state, chapter);
      if (!resource) return;
      resource.child = chapter;
      setCurrentResource(state, resource);
    },
    CHECK_CURRENT_RESOURCE(state: any) {
      state.currentResource.checked = true;
      if(upStreamQuizIsEnded(state) && !downStreamQuizIsEnded(state)){
        alertSuccess('Vous pouvez commencer le module !', 5000);
        return;
      }
    },
    NEXT_RESOURCE(state: any) {
      if(!upStreamQuizIsEnded(state)){
        alertInfo('Veuillez compléter le quiz', 5000);
        return;
      }

      if (!state.currentResource) return;
      const list = flatModuleList(state);
      const index = list.map((src: any) => src.id)
        .indexOf(state.currentResource.id);
      if (index === -1 || index === (list.length - 1)) return;
      setCurrentResource(state, list[index + 1]);
    },
    SET_SIDE_MENU_OPEN_STATE(state: any, value: any) {
      state.sideMenuopenState = value;
      if (!!value) {
        document.body.classList.add('no-scroll-mobile');
      } else {
        document.body.classList.remove('no-scroll-mobile');
      }
    },
    PREV_RESOURCE(state: any) {
      if (!state.currentResource) return;
      const list = flatModuleList(state);
      const index = list.map((src: any) => src.id)
        .indexOf(state.currentResource.id);
      if (index < 1) return;
      setCurrentResource(state, list[index - 1]);
    },
    TOGGLE_OPEN_ROOT(state: any, index: number) {
      //Check if at least one quiz is completed
      if(!upStreamQuizIsEnded(state)){
        alertInfo('Veuillez compléter le quiz', 5000);
        return;
      }

      if (state.resources[index] === undefined) return;
      state.resources[index].open = !state.resources[index].open;
      if (state.resources[index].open) {
        state.resources.forEach((resource: any, i: number) => {
          if (i === index) return;
          state.resources[i].open = false;
        });
      }
    },
    TOGGLE_CHECK_RESOURCE(state: any, resource: any) {
      if (CAN_TOGGLE_RESOURCE_STATE) {
        resource.checked = !resource.checked;
        if (resource.children !== undefined) {
          resource.children.forEach((child: any) => {
            child.checked = resource.checked;
          });
        }
      }
    },
    TOGGLE_CHECK_CHAPTER(state: any, chapter: any) {
      if (CAN_TOGGLE_CHAPTER_STATE) {
        const resource = getResourceFromChapter(state, chapter);
        if (!resource) return;
        resource.child = chapter;
        setCurrentResource(state, resource);
      }
    },
    SET_MODULE_PROGRESS(state: any, data: {
      id?: number,
      progress: any,
      quizId?: number,
      srcGroupId?: number,
      type?: string,
      resourceId?: number
    }) {
      if (!state.progress) state.progress = {};
      if (data.quizId !== undefined) {
        if (!state.progress.quiz) state.progress.quiz = {};
        state.progress.quiz[data.quizId] = data.progress;
      }
      if (data.srcGroupId && data.type && data.resourceId) {
        if (!state.progress.srcGroup) state.progress.srcGroup = {};
        if (!state.progress.srcGroup[data.srcGroupId]) state.progress.srcGroup[data.srcGroupId] = {};
        if (!state.progress.srcGroup[data.srcGroupId][data.type]) state.progress.srcGroup[data.srcGroupId][data.type] = {};
        state.progress.srcGroup[data.srcGroupId][data.type][data.resourceId] = data.progress;
      }
    }
  },
  getters: {
    percent: (state: any) => {
      const object = state.resources.reduce((o: { total: number, count: number }, current: any) => {
        if (current.usedInProgress === false) return o;
        o.count += current.children.filter((c: { checked: boolean }) => c.checked).length;
        o.total += current.children.length;
        return o;
      }, {
        total: 0,
        count: 0
      });
      if (object.total === 0) return 0;
      return Math.round((object.count * 100) / object.total);
    },
    progress: (state: any) => {
      return state.progress;
    }
  },
  actions: {
    async fetch(context: ActionContext<any, any>, id: number) {
      if (!context.rootGetters['auth/isAuth']) return;
      const auth = context.rootGetters['auth/authorization'];
      modulesService.withAuthorization(auth);
      context.commit('SET_LOADING', true);
      const response = await modulesService.fetchOwnedModule(id);
      context.commit('SET_MODULE', response.data.module);
      context.commit('SET_LOADING', false);
    },
    async updateProgress(context: ActionContext<any, any>, data: {
      id?: number,
      progress: any,
      quizId?: number,
      srcGroupId?: number,
      type?: string,
      resourceId?: number
    }) {
      if (!context.rootGetters['auth/isAuth'] || !data.id) return;
      const auth = context.rootGetters['auth/authorization'];
      context.commit('SET_MODULE_PROGRESS', data);
      const progress = context.rootGetters['auth/module/progress'];
      modulesService.withAuthorization(auth);
      const response = await modulesService.updateOwnedModuleProgress(data.id, { ...progress });
      return response.data && response.data.saved === true;
    }
  },
};
