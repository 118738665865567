import AppService from '@/services/app.service';

class PlatformTextsService extends AppService {
  async fetch(key: string) {
    return this.exec<any>({
      url: '/v1/platform-texts',
      params: {
        type: key,
      },
    });
  }
}

const platformTextsService = new PlatformTextsService();
export default platformTextsService;
