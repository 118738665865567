import AppService from '@/services/app.service';

class ContactService extends AppService {
  async send(data: any) {
    return this.exec({
      url: '/v1/contact-forms',
      method: 'POST',
      data,
    });
  }
}

const contactService = new ContactService();
export default contactService;
