import { ActionContext } from 'vuex';
import orderService from '@/services/order.service';
import ModuleFactory from '@/services/entities/module.factory';
import ApiResponseInterface from '@/services/interface/api-response.interface';

export default {
  namespaced: true,
  state: {
    loading: false,
    formLoading: false,
    clientSecret: null,
    modules: [],
    price: 0,
    errored: false,
  },
  mutations: {
    INIT_REQUEST(state: any) {
      state.loading = true;
      state.clientSecret = null;
      state.modules = [];
      state.price = 0;
    },
    SET_LOADING(state: any, payload: boolean) {
      state.loading = payload;
    },
    SET_FORM_LOADING(state: any, payload: boolean) {
      state.formLoading = payload;
    },
    SET_DATA(state: any, payload: { intent: { id: string, clientSecret: string, amount: number }, modules: any }) {
      state.paymentIntent = payload.intent.id;
      state.clientSecret = payload.intent.clientSecret;
      state.price = payload.intent.amount;
      state.modules = ModuleFactory.buildMany(payload.modules);
    },
    SET_ERRORED(state: any, errored: boolean) {
      state.errored = errored;
    },
  },
  actions: {
    async fetch(context: ActionContext<any, any>): Promise<boolean|null> {
      context.commit('SET_ERRORED', false);
      if (!context.rootGetters['auth/isAuth']) return null;
      const modules = context.rootState.shoppingCart.modules as number[];
      context.commit('INIT_REQUEST');
      if (modules.length === 0) {
        context.commit('SET_LOADING', false);
        context.commit('SET_ERRORED', true);
        return null;
      }
      orderService.withAuthorization(context.rootGetters['auth/authorization']);
      try {
        const response = await orderService.fetch(modules);
        if (response.data.added) {
          context.commit('SET_LOADING', false);
          return true;
        }
        context.commit('SET_DATA', response.data);
      } catch (e) {
        context.commit('SET_ERRORED', true);
      }
      context.commit('SET_LOADING', false);
      return false;
    },
    async pay(context: ActionContext<any, any>, paymentMethod: string): Promise<any> {
      if (!context.rootGetters['auth/isAuth']) return null;
      if (!context.state.paymentIntent) return null;
      context.commit('SET_FORM_LOADING', true);
      orderService.withAuthorization(context.rootGetters['auth/authorization']);
      const result = await orderService.pay(context.state.paymentIntent, paymentMethod);
      context.commit('SET_FORM_LOADING', false);
      return result;
    },
  },
};
