// eslint-disable-next-line import/no-cycle
import { alertDanger } from '@/_helpers/alert.helper';

export default {
  namespaced: true,
  state: {
    errors: [],
  },
  mutations: {
    CLEAR(state: any, index: number) {
      if (state.errors.length <= index) return;
      state.errors.splice(index, 1);
    },
    ERROR_REQUEST() {
      alertDanger('Une erreur est survenue. Veuillez réessayer plus tard', 10000);
    },
    TIMEOUT_REQUEST() {
      alertDanger('Le délai de réponse du serveur est trop long. Veuillez réessayerp lus tard', 10000);
    },
  },
};
