import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import HttpClient from '@/services/http-client';
import ApiResponseInterface from '@/services/interface/api-response.interface';

export default class AppService {
  private http: AxiosInstance;

  protected headers: any;

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
    };
    this.http = HttpClient;
  }

  withAuthorization(token: string|null): void {
    if (token === null) {
      delete this.headers.Authorization;
      delete this.headers['X-Authorization'];
      return;
    }
    this.headers.Authorization = `Bearer ${token}`;
    this.headers['X-Authorization'] = `Bearer ${token}`;
  }

  async exec<T extends ApiResponseInterface>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const options = config;
    // eslint-disable-next-line
    options.headers = Object.assign({}, this.headers, config.headers);
    return this.http.request(options);
  }
}
