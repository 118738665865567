import { ActionContext } from 'vuex';
import modulesService from '@/services/modules.service';
import { ModuleModalStoreInterface } from '@/store/module/module-modal-store.interface';
import ModuleDetailInterface from '@/services/interface/entities/module-detail.interface';
import PictureEntity from '@/services/entities/picture.entity';
import ModuleFactory from '@/services/entities/module.factory';

export default {
  namespaced: true,
  state: {
    loading: false,
    open: false,
    module: null,
  },
  getters: {},
  mutations: {
    SET_LOADING(state: ModuleModalStoreInterface, payload: boolean): void {
      state.loading = payload;
    },
    SET_OPEN(state: ModuleModalStoreInterface, payload: boolean): void {
      state.open = payload;
    },
    SET_MODULE(state: ModuleModalStoreInterface, module: ModuleDetailInterface|null): void {
      if (!module) {
        state.module = null;
        return;
      }
      state.module = ModuleFactory.buildOne<ModuleDetailInterface>(module);
    },
  },
  actions: {
    async open(context: ActionContext<ModuleModalStoreInterface, any>, id: number): Promise<void> {
      context.commit('SET_LOADING', true);
      context.commit('SET_OPEN', true);
      const response = await modulesService.fetchOne(id);
      context.commit('SET_MODULE', response.data.module);
      context.commit('SET_LOADING', false);
    },
  },
};
