import Vue from 'vue';

/**
 * @link https://qastack.fr/programming/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param bytes
 * @param decimals
 */
function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Octets';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const pow = k ** i;
  return `${parseFloat((bytes / pow).toFixed(dm))} ${sizes[i]}`;
}

Vue.filter('fileSize', (_value: string|number, decimals = 1) => {
  const value = typeof _value === 'string' ? parseInt(_value, 0) : _value;
  if (Number.isNaN(value)) return '0 o';
  return formatBytes(value, decimals).replace('.', ',');
});
