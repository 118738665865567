export interface WithId {
  id: number,
}

export interface VideoChapterInterface extends WithId {
  title: string,
  length: number,
  checked: boolean,
  current: boolean,
  setChapter?: number,
}

export interface BaseResource extends WithId {
  checked: boolean,
  selected: boolean,
  open: boolean,
  content: string,
  title: string,
  logo: string|null,
  parent?: number,
  meta?:any
}

export interface PodcastResouce extends BaseResource {
  type: 'PODCAST',
  source: {
    sound: string,
    thumbnail: string,
  },
}

export interface VideoResource extends BaseResource {
  type: 'VIDEO',
  length: number,
  children: VideoChapterInterface[],
  source: {
    m3u8: string,
    thumbnail: string|null,
    subtitles: string|null,
    chapters: {
      title: string,
      start: number,
      end: number,
      index: number,
    }[],
  }
}

export interface FileResource extends BaseResource {
  type: string,
  size: number,
}

export interface PdfResource extends FileResource {
  type: 'PDF',
  filePath: string,
  thumbnail: string,
}

export interface FolderResource extends BaseResource {
  type: 'FOLDER',
}

export interface QuizResponse {
  label: string,
  isCorrect: boolean,
  color: string,
}

export interface QuizResource extends BaseResource {
  type: 'QUIZ',
  quiz: {
    question: string,
    responses: QuizResponse[],
    upstream: boolean,
  }[],
}

// eslint-disable-next-line no-shadow
export enum GroupResourceColorEnum {
  TIP_BEIGE = 'tip-beige',
  TIP_VIOLET = 'tip-violet',
  TIP_ORANGE = 'tip-orange',
  TIP_JAUNE = 'tip-jaune',
  TIP_ROUGE = 'tip-rouge',
  TIP_BLEU = 'tip-bleu',
  TIP_VERT = 'tip-vert',
  TIP_ROSE = 'tip-rose',
  TIP_BLEU2 = 'tip-bleu2',
  TIP_CORAIL = 'tip-corail',
}

export interface GroupResource {
  id: string,
  icon: string,
  color: GroupResourceColorEnum,
  resourceCount: number,
  title: string,
  open: boolean,
  displayOrder: number,
  children: (FolderResource|PdfResource|VideoResource|QuizResource|PodcastResouce)[],
  altTitle?: string,
  usedInProgress?: boolean,
  behavior?: 'children'|'emit-click'|'open',
  href?: string,
}
