import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Meta from 'vue-meta';
import store from '../store/index';

import RequireAuthGuard from './guards/auth-checked.guard';

Vue.use(VueRouter);
Vue.use(Meta);

function lazyLoad(component: string, prefix = 'pages') {
  return () => import(/* webpackPrefetch: true */ `@/views/${prefix}/${component}.vue`);
}

const routes: Array<RouteConfig> = [
  // {
  //   path: '/',
  //   components: { mainNavigation: lazyLoad('nav-bar-test', 'layout') },
  //   children: [
  //     {
  //       path: '/empty',
  //       name: 'Empty',
  //       components: { layoutNavigation: lazyLoad('Empty') },
  //     },
  //   ],
  // },
  {
    path: '/',
    beforeEnter: RequireAuthGuard(store),
    components: { mainNavigation: lazyLoad('static-page-layout', 'layout') },
    children: [
      {
        path: '/',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Main',
        components: { layoutNavigation: lazyLoad('Home') },
      },
      {
        path: '/conditions-generales-d-utilisations',
        // beforeEnter: RequireAuthGuard(store),
        name: 'CGU',
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
        meta: { type: 'cgu' },
      },
      {
        path: '/conditions-generales-de-vente',
        // beforeEnter: RequireAuthGuard(store),
        name: 'CGV',
        meta: { type: 'cgv' },
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
      },
      {
        path: '/handicap',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Handicap',
        meta: { type: 'handicap' },
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
      },
      {
        path: '/mentions-legales',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Legals',
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
        meta: { type: 'legals' },
      },
      {
        path: '/politique-de-confidentialite',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Privacy',
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
        meta: { type: 'privacy' },
      },
      {
        path: '/conditions-d-accessibilite',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Accessibility',
        components: {
          layoutNavigation: lazyLoad('TextPage'),
        },
        meta: { type: 'accessibility' },
      },
      {
        path: '/accueil',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Home',
        components: { layoutNavigation: lazyLoad('Home') },
      },
      {
        path: '/modules',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Search',
        components: { layoutNavigation: lazyLoad('Search') },
      },
      {
        path: '/live-master-class',
        // beforeEnter: RequireAuthGuard(store),
        name: 'LiveMasterClass',
        components: { layoutNavigation: lazyLoad('LiveMasterClass') },
      },
      {
        path: '/la-plateforme',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Platform',
        components: { layoutNavigation: lazyLoad('Platform') },
      },
      {
        path: '/offres-ecoles-entreprises',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Offers',
        components: { layoutNavigation: lazyLoad('Offers') },
      },
      // {
      //   path: '/tipix',
      // beforeEnter: RequireAuthGuard(store),
      //   name: 'TipiX',
      //   components: { layoutNavigation: lazyLoad('Tipix') },
      // },
      // {
      //   path: '/tipix/:id(\\d+):append(.+)?',
      // beforeEnter: RequireAuthGuard(store),
      //   name: 'TipixDetail',
      //   components: { layoutNavigation: lazyLoad('TipixDetail') },
      // },
      {
        path: '/nous-contacter',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Contact',
        components: { layoutNavigation: lazyLoad('Contact') },
      },
    ],
  },
  {
    path: '/',
    beforeEnter: RequireAuthGuard(store),
    components: { mainNavigation: lazyLoad('custom-padding-bottom-page-layout', 'layout') },
    children: [
      {
        path: '/module/:slug',
        // beforeEnter: RequireAuthGuard(store),
        name: 'ModulePage',
        components: { layoutNavigation: lazyLoad('ModulePage') },
      },
    ],
  },
  {
    path: '/',
    beforeEnter: RequireAuthGuard(store),
    components: {
      mainNavigation: lazyLoad('empty-page-layout', 'layout'),
    },
    children: [
      {
        path: '/mon-panier',
        // beforeEnter: RequireAuthGuard(store),
        name: 'ShoppingCart',
        components: { layoutNavigation: lazyLoad('ShoppingCart') },
      },
      {
        path: '/paiement',
        // beforeEnter: RequireAuthGuard(store),
        name: 'Payout',
        components: { layoutNavigation: lazyLoad('Payout') },
      },
      {
        path: '/paiement/run',
        // beforeEnter: RequireAuthGuard(store),
        name: 'PaymentValidation',
        components: { layoutNavigation: lazyLoad('PaymentValidation') },
      },
    ],
  },
  {
    path: '/',
    components: {
      mainNavigation: lazyLoad('connection-layout', 'layout'),
    },
    children: [
      {
        path: '/se-connecter',
        name: 'LogIn',
        components: {
          layoutNavigation: lazyLoad('LogIn'),
        },
      },
      {
        path: '/creer-un-compte',
        name: 'SignIn',
        components: {
          layoutNavigation: lazyLoad('SignIn'),
        },
      },
      {
        path: '/mot-de-passe-oublie',
        name: 'ForgotRequest',
        components: {
          layoutNavigation: lazyLoad('ForgotRequest'),
        },
      },
      {
        path: '/modifier-mon-mot-de-passe',
        name: 'ResetPassword',
        components: {
          layoutNavigation: lazyLoad('ResetPassword'),
        },
      },
    ],
  },
  {
    path: '/compte',
    beforeEnter: RequireAuthGuard(store),
    components: {
      mainNavigation: lazyLoad('custom-padding-bottom-page-layout', 'layout'),
    },
    children: [
      {
        path: 'module/:id',
        name: 'AccountModuleDetail',
        components: {
          layoutNavigation: lazyLoad('AccountModuleDetail'),
        },
      },
    ],
  },
  {
    path: '/compte',
    beforeEnter: RequireAuthGuard(store),
    components: {
      mainNavigation: lazyLoad('connected-layout', 'layout'),
    },
    children: [
      {
        path: '',
        components: {
          layoutNavigation: lazyLoad('Profile'),
        },
        children: [
          {
            path: 'mon-compte',
            name: 'Profile',
            components: {
              profilePage: lazyLoad('profile/profile-account'),
            },
          },
          {
            path: 'mes-modules',
            name: 'ModuleAccount',
            components: {
              profilePage: lazyLoad('profile/module-account'),
            },
          },
          {
            path: 'mes-factures',
            name: 'InvoiceAccount',
            components: {
              profilePage: lazyLoad('profile/invoice-account'),
            },
          },
        ],
      },
    ],
  },
  {
    path: '/404-page-not-found',
    components: { mainNavigation: lazyLoad('static-page-layout', 'layout') },
    children: [
      {
        path: '',
        name: '404',
        components: {
          layoutNavigation: lazyLoad('error-404'),
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404-page-not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('topBar/SET_OPEN', false);
  store.commit('SET_ROUTE_LOADING', true);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
  next();
});

router.afterEach(() => {
  store.commit('SET_ROUTE_LOADING', false);
});

export default router;
