import { AxiosResponse } from 'axios';
import homeService from '@/services/home.service';
import ApiResponseInterface from '@/services/interface/api-response.interface';

const storage = {
  hasSeen(id: string) {
    return localStorage.getItem('lodylearn_home_popup_ref') === id;
  },
  persist(id: string) {
    localStorage.setItem('lodylearn_home_popup_ref', id);
  },
};

export interface HomePopupResponseInterface extends ApiResponseInterface {
  homePopup: {
    button: { link: string, target: string, text: string },
    picture: { height: number, path: string, preview: string|null, width: number },
    storeRef: number,
  }|null,
}

export default {
  namespaced: true,
  state: {
    open: false,
    popup: null,
  },
  mutations: {
    SET_OPEN(state: any, payload: any) {
      state.open = payload;
      if (!payload) {
        storage.persist(state.popup.storeRef.toString());
      }
    },
    SET_POPUP(state: any, payload: any) {
      state.popup = payload;
    },
  },
  actions: {
    async fetch(context: any) {
      const response:AxiosResponse<HomePopupResponseInterface> = await homeService.fetchPopupConfig();
      const { homePopup } = response.data;
      context.commit('SET_POPUP', homePopup);
      if (!homePopup) return;
      const ref = homePopup.storeRef.toString();
      context.commit('SET_OPEN', !storage.hasSeen(ref));
    },
  },
};
