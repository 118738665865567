import { Store } from 'vuex';
import { deepAssign, deepValue } from '@/_helpers/object.helper';
import { StorageInterface } from '@/_helpers/storage/storage.interface';
import localStore from '@/_helpers/storage/local-store';

const staticStore: StorageInterface = localStore;

function write(key: string, value: any) {
  return staticStore.set(key, JSON.stringify(value));
}

function rm(key: string) {
  return staticStore.remove(key);
}

function read(key: string) {
  const tmp = staticStore.get(key);
  if (tmp === undefined) return undefined;
  if (!tmp) return null;
  try {
    return JSON.parse(tmp);
  } catch (e) {
    rm(key);
    return null;
  }
}

function clear() {
  staticStore.clear();
}

const persistMutations = (options: { mutations: string[] }) => ((store: Store<any>) => {
  store.subscribe((mutation, state) => {
    if (options.mutations.indexOf(mutation.type) === -1) return;
    write(mutation.type, mutation.payload);
  });
});

const persistState = (options: { paths: string[] }) => ((store: Store<any>) => {
  store.subscribe((mutation, state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const path of options.paths) {
      write(`state#${path}`, deepValue(state, path));
    }
  });
});

const importMutations = (options: {
  mutations: string[], guards?: {
    mutation: string, isValid: (v: any) => boolean
  }[]
}) => ((store: Store<any>) => {
  if (options.guards) {
    // eslint-disable-next-line no-restricted-syntax
    for (const guard of options.guards) {
      if (!guard.isValid(read(guard.mutation))) {
        return;
      }
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const mutation of options.mutations) {
    const payload = read(mutation);
    store.commit(mutation, payload);
  }
});

const importState = (options: { paths: {path: string, defaultValue: any}[] }) => ((store: Store<any>) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const v of options.paths) {
    const payload = read(`state#${v.path}`) || v.defaultValue;
    deepAssign(store.state, v.path, payload);
  }
});

export default {
  persistMutations,
  importMutations,
  persistState,
  importState,
  clear,
};
