import globalTextsService from '@/services/global-texts.service';

function storeFactory() {
  return {
    namespaced: true,
    state: {
      open: false,
      content: null,
      loading: false,
    },
    mutations: {
      SET_LOADING(state: any, payload: boolean) {
        state.loading = payload;
      },
      SET_CONTENT(state: any, payload: any) {
        state.content = payload;
      },
      SET_OPEN(state: any, payload: boolean) {
        state.open = payload;
      },
    },
    actions: {
      async init(context: any, key: string) {
        if (context.state.content) return;
        await context.dispatch('fetch', key);
      },
      async fetch(context: any, key: string) {
        context.commit('SET_LOADING', true);
        const response = await globalTextsService.fetch(key);
        context.commit('SET_CONTENT', response.data.content);
        context.commit('SET_LOADING', false);
      },
    },
  };
}

export default {
  namespaced: true,
  modules: {
    cgu: storeFactory(),
    cgv: storeFactory(),
    handicap: storeFactory(),
    accessibility: storeFactory(),
    legals: storeFactory(),
    privacy: storeFactory(),
  },
};
